.tooltip {
    position: relative;
    display: inline-block;
    border-bottom: 1px dotted black;
  }
  
  .tooltip .tooltiptext {
    visibility: hidden;
    width: 120px;
    background-color: black;
    color: white;
    text-align: center;
    border-radius: 6px;
    padding: 5px 0;
    position: absolute;
    z-index: 1;
    bottom: 150%;
    left: 50%;
    margin-left: -60px;
  }
  
  .tooltip .tooltiptext::after {
    content: "";
    position: absolute;
    top: 100%;
    left: 50%;
    margin-left: -5px;
    border-width: 5px;
    border-style: solid;
    border-color: black transparent transparent transparent;
  }
  
  .tooltip:hover .tooltiptext {
    visibility: visible;
  }
 
  .tooltipUserSummary {
    position: relative;
    display: inline-block;
    border-bottom: 1px dotted black;
  }
  
  .tooltipUserSummary .tooltiptext {
    visibility: hidden;
    width: 130px;
    background-color: grey;
    color: white;
    text-align: center;
    border-radius: 6px;
    padding: 5px 0;
    position: absolute;
    z-index: 1;
    bottom: 50%;
    left: -40%;
    margin-left: 0px;
  }
  
  .tooltipUserSummary .tooltiptext::after {
    content: "";
    position: absolute;
    top: 100%;
    left: 50%;
    margin-left: -25px;
    border-width: 5px;
    border-style: solid;
    border-color: grey transparent transparent transparent;
  }
  
  .tooltipUserSummary:hover .tooltiptext {
    visibility: visible;
  }
  