.intro {
  max-width: 90%;
  margin: auto;
  line-height: 1.8rem;
  font-size: larger;
}

.container h2,
h3 {
  font-weight: 600;
  text-align: center;
}

ul,
ol {
  width: fit-content;
  margin: auto;
}

.container {
  margin: 4rem;
  line-height: 2rem;
}

@media screen and (max-width: 800px) {
  .container {
    margin: 3rem 0;
  }
  .container h2,h3{
    font-weight: 600;
    font-size: 2rem !important;
  }
}
