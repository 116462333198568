body {
    margin: 0;
    font-family: -apple-system,BlinkMacSystemFont,"Segoe UI","Roboto","Oxygen","Ubuntu","Cantarell","Fira Sans","Droid Sans","Helvetica Neue",sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}
  
  table {
    width: 100%;
    border-collapse: collapse;
  }
  thead{

      padding-bottom: 2em;
  }
  
  thead th {
    text-align: center;
    padding: 0px;
    padding-top:  0.5em;
    padding-bottom: 1em;
    padding-left: 0.5em;
    padding-right:0.5em;
    border-bottom: 0.1rem solid #e1e1e1;
    word-break: break-all;
    white-space: nowrap;
    
  }

  thead th:first-of-type{
    text-align: center;
    padding:0px;
    padding-left: 0.5em;
    padding-right:0.5em;
    border-bottom: 0.1rem solid #e1e1e1;
  }
  
  thead button {
    border: 0;
    border-radius: none;
    font-family: inherit;
    padding:0px;
    font-size:1em;
    font-weight: 700;
    margin-bottom: 1px;
    background-color: white;
    color:black;
    word-break: break-all;
    white-space: nowrap;
    text-align: center;
    text-transform: none;
  }

  thead button:hover {
   
    background-color: white;
    color:black;
  }

  thead button:focus {
   
    background-color: white;
    color:black;
  }
  
  thead button.ascending::after {
    content: '▲';
    display: inline-block;
    margin-left: 0.5em;
    background-color: white;
    color: black;
  }
  
  thead button.descending::after {
    content: '▼';
    display: inline-block;
    margin-left: 0.5em;
    background-color: white;
    color: black;
  }
  
  tbody tr td {
    padding-top:  0.5em;
    padding-bottom: 1em;
    padding-left: 0.5em;
    padding-right:0.5em;
    border-bottom: 0.1rem solid #e1e1e1;
    word-break: break-all;
    white-space: nowrap;
    text-align: center;
  }

 

  tbody td:first-of-type {
    padding: 0px;
    padding-top:  0.5em;
    padding-bottom: 1em;
    padding-right:0em;
    padding-left: 0em; 
    word-break: break-all;
    white-space: nowrap;
    text-align: center;
    border-bottom: 0.1rem solid #e1e1e1;;
  }


  
  tbody tr:hover {
    background-color: white;
  }
  