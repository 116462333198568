.alerts-section {
  max-width: 90%;
  margin: auto;
  line-height: 1.8rem;
  font-size: larger;
}

.alertsHeader {
  all: unset;
  margin: 1.5rem 0;
  font-size: 16px;
  font-weight: 700;
  color:black;
}

.alertsHeader p {
    font-size: 16px;
    font-weight: 700;
    color:black;
  }

.address {
  word-wrap: anywhere;
  width: fit-content;
  margin: auto;
}

.alertsContainer {
  all: unset;
  margin: 0;
  width: fit-content;
  margin: 1rem auto;
}

.alertsBox {
  display: flex;
  width: 70%;
  margin: 0 auto;
  padding: 20px;
  background-color: lightgray;
  color: white;
  margin-bottom: 15px;
  justify-content: space-between;
  align-items: center;
}

.alertsList {
  margin: 0;
  padding: 0;
  color: black;
  padding-left: 1rem;
}

.alertsBox input {
  transform: scale(1.3);
}

.buttonsContainer {
  display: flex;
  justify-content: space-evenly;
  max-width: 80%;
  margin: 1rem auto;
}

.buttonsContainer button {
  all: unset;
  padding: 15px 32px;
  text-align: center;
  font-size: 16px;
  cursor: pointer;
  background-color: white;
  color: black;
  text-decoration: none;
  border: 2px solid maroon;
  transition-duration: 0.4s;
  border-radius: 5px;
 }
.buttonsContainer button:hover {
  background-color: lightgray;
  color: white;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.24),
    0 5px 5px 0 rgba(0, 0, 0, 0.19);
}

.buttonsContainer .disabled {
  background-color: none !important;
  border: 1px solid #999999;
  background-color: #cccccc !important;
  color: #666666;
  cursor: default;
}

.buttonsContainer .disabled:hover {
  outline: none;
  background-color: #cccccc;
  color: #666666;
  box-shadow: none;
}

.noAlerts {
  width: fit-content;
  margin: 10rem auto;
}

.noAlerts h3,
h2 {
  text-align: center;
}

.loading {
  max-width: fit-content;
  margin: 10rem auto;
}

.loading h2 {
  font-weight: 300;
}

.validator {
  font-size: 1.5rem !important;
}

@media screen and (max-width: 800px) {
  .noAlerts {
    max-width: 80% !important;
  }

  .noAlerts,
  h2 {
    font-size: larger;
  }

  .noAlerts h3 {
    word-wrap: anywhere;
    font-size: medium;
  }

  .alertsHeader p {
    text-align: center;
    font-size: medium;
  }

  .alertsBox ul {
    font-size: smaller;
  }

  .alertsBox .validator {
    font-size: small;
  }
}
