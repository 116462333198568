.datePicker {
    margin: 0;
    box-sizing: border-box;
    padding: 5px 5px 0px 0px;
    width: 230px;
    border-top: 1px solid var(--foreground-color);
  
    &__hint {
      font-style: italic;
      color: var(--primary-text-color--faint);
      padding-bottom: 10px;
    }
  
    &__input {
      display: flex;
      align-items: center;
      justify-content: center;
    }
  
    &__cancel {
      padding-left: 10px;
    }
  
    &--error .react-datepicker__input-container {
      border-color: $error-red !important;
      width: 100%;
    }
  }
  
  .react-datepicker {
    font-size: .8em;
  }
  .react-datepicker__header {
    padding-top: 0.8em;
  }
  .react-datepicker__month {
    margin: 0.4em 1em;
  }
  .react-datepicker__day-name, .react-datepicker__day {
    width: 1.9em;
    line-height: 1.9em;
    margin: 0.166em;
  }
  .react-datepicker__current-month {
    font-size: 1em;
  }
  .react-datepicker__navigation {
    top: 1em;
    line-height: 1.7em;
    border: 0.45em solid transparent;
  }
  .react-datepicker__navigation--previous {
    border-right-color: #ccc;
    left: 1em;
  }
  .react-datepicker__navigation--next {
    border-left-color: #ccc;
    right: 1em;
  }