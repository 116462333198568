.coinImage {
    width: 48px;
    height: auto;
}

.coinImageSmall {
  width: 30px;
  height: auto;
}

.select-list {
  min-width: 70px !important;
  font-size: 14px;
  padding: 5px 10px 5px 10px;
  appearance: none;
  margin-right: 10px;
}
.select-list:hover {
  color: black;    
  cursor: pointer;
  border-color: red;
}

.select-list:focus {
    border-color: red;
    color: black;    
}

/* date picker formatting 
.datePicker {
  background-color: white; 
  color: white;
  vertical-align: center;
  width: 40%;
  padding: 10px 15px 10px 15px;
  border: none;
  border-radius: 50px;
  outline: none;
  font-size: 15px;
  margin-right: 20px;
  -webkit-box-sizing: border-box; /* Safari/Chrome, other WebKit */
/*  -moz-box-sizing: border-box;    /* Firefox, other Gecko */
/* box-sizing: border-box;         /* Opera/IE 8+ */
/*}
*/

/* search bar formatting */
.form {
  display: flex;
  flex-direction: row;
}
.search-field {
  width: 100%;
  padding: 10px 15px 10px 15px;
  border: none;
  border-radius: 50px;
  background-color: black;
  outline: none;
  font-size: 15px;
  margin-right: 20px;
  -webkit-box-sizing: border-box; /* Safari/Chrome, other WebKit */
  -moz-box-sizing: border-box;    /* Firefox, other Gecko */
  box-sizing: border-box;         /* Opera/IE 8+ */
}

.search-field:hover {
  color: black;
  border-color: red;
}
.search-field:focus {
  color: black;
  border-color: yellow;
  background-color: white;
  border:1px solid red;
}

.search-button {
  background: transparent;
  border: none;
  display: inline-block;
  position: absolute;
  top: 0;
  right: 0;
  padding: 8px 25px 10px 25px;
  z-index: 2;
}
.search-button:hover{
  background: transparent;
  cursor: pointer;
}
.search-button:focus{
  background: transparent;
}

.search-button img {
  width: 20px;
  height: 20px;
  object-fit: cover;
}



.search-button-snapshotRewards {
  background: transparent;
  border: none;
  align-items: left;
  display: inline-block;
  position: absolute;
  top: 0;
  right: 10;
  padding: 12px 0px 0px 5px;
  z-index: 2;
}
.search-button-snapshotRewards:hover{
  background: transparent;
  cursor: pointer;
}
.search-button-snapshotRewards:focus{
  background: transparent;
}

.search-button-snapshotRewards img {
  width: 20px;
  height: 20px;
  object-fit: cover;
}



.search-button-incomeRewards {
  background: transparent;
  border: none;
  display: inline-block;
  position: absolute;
  top: 0;
  right: 10;
  padding: 12px 10px 0px 5px;
  z-index: 2;
}
.search-button-incomeRewards:hover{
  background: transparent;
  cursor: pointer;
}
.search-button-incomeRewards:focus{
  background: transparent;
}

.search-button-incomeRewards img {
  width: 20px;
  height: 20px;
  object-fit: cover;
}
