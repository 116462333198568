/* This container wraps the three elements that links to the social networks */
.footer{
    display: flex;
    justify-content: center;
    width: 100%;
    margin-top: 0px;
    padding-bottom: 0px;
    flex-wrap: wrap;
    min-width: 20px;
    font-size: 11px;
    color: black;
    font-weight: 350;
    
}


.footer_stake{
    display: flex;
    justify-content: center;
    width: 100%;
    margin-top: 0px;
    padding-bottom: 0px;
    flex-wrap: wrap;
    min-width: 20px;
    font-size: 16px;
    color: black;
    
}

/* The icon used for telegram */
.footer_logo{
    font-size: 0px;
    color: #0088cc; 
    margin-left: 0px;
    display: flex;
    justify-content: center;
    width: 100%;
    margin-top: 0px;
    padding-bottom: 0px;
    flex-wrap: wrap;
    min-width: 20px;
    font-size: 12px;
}

/* The icon used for donate button */
.footer_donate{
    justify-content: center;
    width: 120px;
}


/* The icon used for telegram */
.footer_telegram{
    font-size: 12px;
    color: grey; 
    margin-left: 0px;
    justify-content: center;
}

/* when hovered over the icons cursor changes to pointer */
.cursor{
    cursor: pointer;
    margin: 20px;
   
}
