.react-tabs {
    -webkit-tap-highlight-color: transparent;
 }
  
  .react-tabs__tab-list {
    border-bottom: 1px solid lightgray;
    margin: 0px 0px 10px 0px;
    padding: 0;
  }
  
  .react-tabs__tab {
    display: inline-block;
    border-top: 1px solid white;
    border-left: 1px solid white;
    border-right: 1px solid white;
    background: rgb(224, 218, 218);
    bottom: -11px;
    position: relative;
    list-style: none;
    padding: 0px 12px;
    cursor: pointer;
    border-radius: 8px 18px 0 0;
    font-weight: 400;
  }
  
  .react-tabs__tab--selected {
    box-shadow: 0 0  white;
    background: lightgray;
    border-top: 1px solid white;
    border-left: 1px solid white;
    border-right: 1px solid white;
    background: maroon;
    color: white;
    }
  
  .react-tabs__tab--disabled {
    cursor: default;
  }
  
  .react-tabs__tab:focus {
    box-shadow: 0 0 white;
    border-color: white;
    outline: none;
    
  }
  
  .react-tabs__tab:focus:after {
    content: "";
    position: absolute;
    height: 5px;
    left: -4px;
    right: -4px;
    bottom: -5px;
    background: #fff;
  }
  
  .react-tabs__tab-panel {
    display: none;
    background:white;
  }
  
  .react-tabs__tab-panel--selected {
    display: block;

    background: white;
  }
  